import React, { useState } from 'react'
import styled from 'styled-components'
import useOrders from '../../hooks/useOrders'
import { Swiper, SwiperSlide } from 'swiper/react'
import Button from '../shared/uiComponents/button'

import { mobileVW, colors, desktopBreakpoint, font } from '../../styles'

const StyledOrderHistory = styled.section`
  color: ${colors.brownDarkest};
  padding-left: ${mobileVW(16)};
  padding-bottom: ${mobileVW(16)};

  img {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    height: ${mobileVW(164)};
    @media (min-width: ${desktopBreakpoint}) {
      height: 164px;
    }
  }

  p {
    text-align: center;
    margin-top: ${mobileVW(20)};
    @media (min-width: ${desktopBreakpoint}) {
      margin-top: 20px;
    }
  }

  .button_wrapper {
    margin-top: ${mobileVW(49)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    padding-left: 0;
    padding-bottom: 0;
    max-width: 1280px;
    margin: 0 auto 0 40px;
    h2 {
      text-transform: lowercase;
      text-align: center;
    }

    .button_wrapper {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    background-color: rgba(238, 227, 218, 0.25);
    padding: 17px 35px 19px 29px;
    margin-bottom: 15px;
  }
`

const Title = styled.h4`
  font-family: ${font.ben};
  font-size: ${mobileVW(28)};
  line-height: ${mobileVW(31)};
  color: ${colors.brownDark};
  margin-bottom: ${mobileVW(2)};
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 28px;
    line-height: 31px;
    margin-bottom: 2px;
    margin-top: 0;
  }
`
const Details = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${font.larsseit};
  color: #c8c8c8;
  text-transform: uppercase;
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(24)};
  margin-bottom: ${mobileVW(8)};

  span:nth-child(2) {
    color: ${colors.brownDark};
    margin-right: ${mobileVW(16)};
    @media (min-width: ${desktopBreakpoint}) {
      margin-right: 0;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    line-height: 24px;
    margin-bottom: 17px;
    font-size: 14px;
  }
`

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 95%,
    rgba(255, 255, 255, 0.8) 99%,
    rgba(255, 255, 255, 1) 100%
  );
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const SectionTitle = styled.h3`
  font-family: ${font.ben};
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  margin-top: ${mobileVW(50)};
  text-align: center;
  color: ${colors.brownDarkest};
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    display: ${props => (props.show ? 'block' : 'none')};
    font-size: 24px;
    line-height: 1;
    margin-top: 0;
  }
`

const ItemsOverlay = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.25);
    font-size: 48px;
    line-height: 52px;
    color: white;
  }
`

const Orderhistory = () => {
  const orders = useOrders()

  const [showAll, setShowAll] = useState(false)

  const formatDate = date => {
    let x = new Date(date)
    let months = [
      'januari',
      'februari',
      'maart',
      'april',
      'mei',
      'juni',
      'juli',
      'augustus',
      'september',
      'oktober',
      'november',
      'december',
    ]
    return `${x.getDate()} ${months[x.getMonth()]} ${x.getFullYear()}`
  }

  return (
    <StyledOrderHistory id='bestellingen'>
      <SectionTitle show={orders && orders.length < 1}>
        laatste bestelling
      </SectionTitle>
      {orders && orders.length < 1 ? (
        <p>Je hebt nog geen bestelling geplaatst.</p>
      ) : orders && orders.length > 1 ? (
        orders.map((order, i) => (
          <Wrapper key={i} show={showAll || i === orders.length - 1}>
            <Title>happily arrived</Title>
            <Details>
              <span>bezorgd op {formatDate(order.processedAt)} </span>
              <span>€{order.totalPrice.amount}</span>
            </Details>
            <Swiper
              slidesPerView={2.1}
              spaceBetween={5}
              observeParents
              observer
              observeSlideChildren
              breakpoints={{
                1024: {
                  initialSlide: 0,
                  spaceBetween: 15,
                  slidesPerView: 4,
                  allowTouchMove: false,
                },
              }}
            >
              {order.lineItems.edges.map((item, i) => (
                <SwiperSlide key={i}>
                  <img src={item.node.variant.image.transformedSrc} />
                  {order.lineItems.edges.length > 5 && i == 4 ? (
                    <ItemsOverlay>
                      <div>+ {order.lineItems.edges.length - 4}</div>
                    </ItemsOverlay>
                  ) : null}
                </SwiperSlide>
              ))}
              <Overlay />
            </Swiper>
          </Wrapper>
        ))
      ) : null}
      {orders && orders.length > 1 && !showAll ? (
        <Button
          cta={() => setShowAll(true)}
          ctaType={'terciary'}
          text={'bestelgeschiedenis'}
        />
      ) : null}
    </StyledOrderHistory>
  )
}

export default Orderhistory
