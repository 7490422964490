import React, { useRef } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import { desktopBreakpoint } from '../../styles'

const Wrapper = styled.div`
  overflow: hidden;

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    overflow: initial;

    @media (min-width: ${desktopBreakpoint}) {
      max-width: 262px;
      width: 262px;
    }
  }
`

const AccountSwiper = ({ children, onReachEnd: _onReachEnd = null }) => {
  const end = useRef(false)

  return (
    <Wrapper>
      <Swiper
        slidesPerView={2.05}
        observeParents
        observer
        observeSlideChildren
        onTransitionEnd={e => {
          if (!end.current) return
          if (!(_onReachEnd && typeof _onReachEnd === 'function')) return

          end.current = false

          _onReachEnd(e)
        }}
        onReachEnd={e => {
          end.current = true
        }}
      >
        {children ? (
          Array.isArray(children) ? (
            children.map((child, i) => (
              <SwiperSlide key={child.key || i}>
                <child.type {...child.props}></child.type>
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>{children}</SwiperSlide>
          )
        ) : null}
      </Swiper>
    </Wrapper>
  )
}

export default AccountSwiper
