import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { graphql, navigate } from 'gatsby'
import firebase from 'firebase/app'
import 'firebase/firestore'
import {
  colors,
  mobileVW,
  letterSpacing,
  font,
  desktopBreakpoint,
  headerHeight,
} from '../styles'

// Utils
import { getPathname } from '../utils'

// Hooks
import useAuth from '../hooks/useAuth'
import { useDictionaryCtx, useLayoutCtx } from '../hooks/context'

// Components
import Image from 'gatsby-image'
import LogoutIcon from '../components/graphics/LogoutIcon'
import CreateAccount from '../components/account/CreateAccount'
import AccountDetails from '../components/account/AccountDetails'
import Step2 from '../components/account/Step2'
import Button from '../components/Button'
import DropdownMenu from '../components/shared/dropdownMenu'
import SEO from '../components/shared/SEO'
import OrderHistory from '../components/account/OrderHistory'
import { SavedGallery, SavedSwiper } from '../components/account/Saved'

const StyledAccount = styled.section`
  height: 100vh;

  color: ${colors.orange};
  padding-top: ${headerHeight.mobile}px;
  @media (min-width: ${desktopBreakpoint}) {
    overflow: hidden;
    padding-top: ${headerHeight.desktop}px;
  }
`

const Banner = styled.div`
  position: relative;
  min-height: 150px;
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const AccountWrapper = styled.div`
  min-height: 100vh;
  display: block;
  width: 100%;
  padding: ${mobileVW(35)} 0 0;

  @media (min-width: ${desktopBreakpoint}) {
    padding: 122px 0 75px;
  }
`

const WelcomeMessage = styled.h1`
  text-transform: lowercase;
  font-family: ${font.ben};
  font-size: ${mobileVW(48)};
  padding: 0 ${mobileVW(70)};
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.beigeUltraLight};
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 100px;
    padding: 0 260px;
  }
`

const Details = styled.div`
  margin-top: ${mobileVW(30)};
`

const NotLoggedIn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 80vh;
  font-family: ${font.larsseit};
  font-size: ${mobileVW(24)};
  letter-spacing: ${letterSpacing(6)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 32px;
    margin-top: 80px;
  }
  a {
    margin-top: ${mobileVW(40)};

    @media (min-width: ${desktopBreakpoint}) {
      margin-top: 80px;
    }
  }
`

const MobileLayout = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`
const DesktopLayout = styled.div`
  display: none;
  @media (min-width: ${desktopBreakpoint}) {
    display: grid;
    height: 100vh;
    padding: 56px;
    grid-gap: 40px;
    overflow-y: scroll;
    grid-template-columns: 363px auto;

    img {
      border-radius: 6px;
    }
  }
`

const MenuWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    width: 363px;
    height: fit-content;
    background-color: rgba(238, 227, 218, 0.25);
    padding: 45px 0 0;
    border-radius: 6px;
    position: sticky;
    top: 0;
    text-transform: lowercase;

    p {
      font-family: ${font.ben};
      font-size: 36px;
      line-height: 39px;
      color: ${colors.brownDarkest};
      text-align: center;
      max-width: 323px;
      margin: 0 auto 30px;
      text-transform: lowercase;
      font-weight: normal;
    }

    > div:last-of-type {
      * {
        border-bottom: none;
      }
    }
  }
`

const View = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  max-width: 850px;
`

const LogoutButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: lowercase;
  padding: ${mobileVW(10)} ${mobileVW(19)};
  font-family: ${font.ben};
  color: ${colors.brownDarkest};
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    border: solid 1px ${colors.beigeUltraLight};
    padding: 10px 19px;
    position: absolute;
    bottom: -82px;
    border-radius: 6px;
  }

  span {
    font-size: ${mobileVW(18)};
    margin-left: ${mobileVW(25)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 18px;
      margin-left: 25px;
    }
  }
`

const ActiveView = styled.div`
  height: 100%;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Icon = styled.div`
  width: ${mobileVW(40)};
  height: ${mobileVW(40)};
  border-radius: 50%;
  background-color: ${colors.beigeUltraLight};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${desktopBreakpoint}) {
    width 40px;
    height: 40px;
  }
`

const Account = ({
  data: {
    contentfulAccountPage: {
      slug,
      seoTitle,
      seoDescription,
      loggedInMessage,
      orderNumber,
      personalInfo,
      savedArticles,
      savedProducts,
      myPreferences,
      orders,
      savedArticlesPlaceholder,
      savedProductsPlaceholder,
      showMoreArticles,
      showMoreProducts,
      seeOrderDetails,
      trackAndTraceCode,
      notLoggedInMessage,
      notLoggedInButtonText,
      notLoggedInLink,
      displayName,
      updateAccountDetailsSuccesMessage,
      updateAccountDetailsFailedMessage,
      save,
      bannerTitle,
      bannerImage,
      step1Message,
      step3Message,
      accountReadyMessage,
      userPreferences,
    },
  },
}) => {
  const dictionary = useDictionaryCtx()
  const { ready, user, signOut } = useAuth()

  // const [detailsActive, setDetailsActive] = useState(null)
  const LoggedInMessageSegments = loggedInMessage.split(/{[^}]+}/)
  const { node_locale } = useLayoutCtx()

  const logOut = async () => {
    await signOut()
    navigate(`/${node_locale}`)
  }

  const [firstTimeUser, setFirstTimeUser] = useState(false)

  const [activeView, setActiveView] = useState('personal')

  const dbUser =
    ready && user && firebase && firebase.firestore().collection('users')

  useEffect(() => {
    dbUser &&
      dbUser
        .doc(user.uid)
        .collection('userInfo')
        .doc('info')
        .onSnapshot(doc => {
          let userInfo = doc.data()
          userInfo &&
            setFirstTimeUser(userInfo.registrationComplete ? false : true)
        })
  }, [user])

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} slug={slug} />
      <StyledAccount>
        {ready && user && firstTimeUser && (
          <CreateAccount
            userPreferences={userPreferences}
            image={bannerImage}
            title={bannerTitle}
            step1Message={step1Message}
            step3Message={step3Message}
          />
        )}
        {ready && user && !firstTimeUser && (
          <>
            <Banner>
              <WelcomeMessage>
                {LoggedInMessageSegments[0]}
                {user.displayName.split(' ')[0]}
                {LoggedInMessageSegments[1]}
              </WelcomeMessage>
            </Banner>
            <MobileLayout>
              <AccountWrapper>
                <DropdownMenu title={personalInfo} iconName={'account'}>
                  <AccountDetails
                    displayNameTitle={displayName}
                    updateAccountDetailsSuccesMessage={
                      updateAccountDetailsSuccesMessage
                    }
                    updateAccountDetailsFailedMessage={
                      updateAccountDetailsFailedMessage
                    }
                    save={save}
                  />
                </DropdownMenu>
                <DropdownMenu title={myPreferences} iconName={'settings'}>
                  <Step2
                    userPreferences={userPreferences}
                    customTitle={myPreferences}
                    overviewPage
                    updateAccountDetailsSuccesMessage={
                      updateAccountDetailsSuccesMessage
                    }
                    updateAccountDetailsFailedMessage={
                      updateAccountDetailsFailedMessage
                    }
                  />
                </DropdownMenu>
                <DropdownMenu title={orders} iconName={'bag'}>
                  <OrderHistory
                    orderNumber={orderNumber}
                    seeOrderDetails={seeOrderDetails}
                    trackAndTraceCode={trackAndTraceCode}
                  />
                </DropdownMenu>
                <LogoutButton
                  onClick={() => logOut()}
                  aria-label='logout account'
                >
                  <Icon>
                    <LogoutIcon />
                  </Icon>
                  <span>{dictionary.logOut}</span>
                </LogoutButton>
                <Details>
                  <SavedSwiper
                    itemType='article'
                    title={savedArticles}
                    collection='articles'
                    placeholder={savedArticlesPlaceholder}
                  />

                  <SavedSwiper
                    itemType='product'
                    title={savedProducts}
                    collection='products'
                    placeholder={savedProductsPlaceholder}
                  />
                </Details>
              </AccountWrapper>
            </MobileLayout>
            <DesktopLayout>
              <MenuWrapper>
                <p>
                  {LoggedInMessageSegments[0]}
                  {user.displayName.split(' ')[0]}
                  {LoggedInMessageSegments[1]}
                </p>
                <DropdownMenu
                  title={personalInfo}
                  iconName={'account'}
                  callback={() => setActiveView('personal')}
                  active={activeView == 'personal'}
                />
                <DropdownMenu
                  title={myPreferences}
                  iconName={'settings'}
                  callback={() => setActiveView('settings')}
                  active={activeView == 'settings'}
                />
                <DropdownMenu
                  title={orders}
                  iconName={'bag'}
                  callback={() => setActiveView('orders')}
                  active={activeView == 'orders'}
                />
                <DropdownMenu
                  title={savedArticles}
                  iconName={'bookmark'}
                  callback={() => setActiveView('articles')}
                  active={activeView == 'articles'}
                />
                <DropdownMenu
                  title={savedProducts}
                  iconName={'bookmark'}
                  callback={() => setActiveView('producten')}
                  active={activeView == 'producten'}
                />
                <LogoutButton
                  onClick={() => logOut()}
                  aria-label='logout account'
                >
                  <Icon>
                    <LogoutIcon />
                  </Icon>
                  <span>{dictionary.logOut}</span>
                </LogoutButton>
              </MenuWrapper>
              <ActiveView>
                <View show={activeView == 'orders'}>
                  <OrderHistory
                    orderNumber={orderNumber}
                    seeOrderDetails={seeOrderDetails}
                    trackAndTraceCode={trackAndTraceCode}
                  />
                </View>
                <View show={activeView == 'personal'}>
                  <AccountDetails
                    displayNameTitle={displayName}
                    updateAccountDetailsSuccesMessage={
                      updateAccountDetailsSuccesMessage
                    }
                    updateAccountDetailsFailedMessage={
                      updateAccountDetailsFailedMessage
                    }
                    save={save}
                  />
                </View>
                <View show={activeView == 'articles'}>
                  <SavedGallery
                    itemType='article'
                    collection='articles'
                    placeholder={savedArticlesPlaceholder}
                    showMoreLabel={showMoreArticles}
                  />
                </View>
                <View show={activeView == 'producten'}>
                  <SavedGallery
                    itemType='product'
                    collection='products'
                    placeholder={savedProductsPlaceholder}
                    showMoreLabel={showMoreProducts}
                  />
                </View>
                <View show={activeView == 'settings'}>
                  <Step2
                    userPreferences={userPreferences}
                    customTitle={myPreferences}
                    overviewPage
                    updateAccountDetailsSuccesMessage={
                      updateAccountDetailsSuccesMessage
                    }
                    updateAccountDetailsFailedMessage={
                      updateAccountDetailsFailedMessage
                    }
                  />
                </View>
              </ActiveView>
            </DesktopLayout>
          </>
        )}
        {!user && ready && (
          <NotLoggedIn>
            {notLoggedInMessage}
            <Button to={getPathname(node_locale, notLoggedInLink.slug)}>
              {notLoggedInButtonText}
            </Button>
          </NotLoggedIn>
        )}
      </StyledAccount>
    </>
  )
}

export const PageQuery = graphql`
  query Account($id: String!) {
    contentfulAccountPage(id: { eq: $id }) {
      slug
      seoTitle
      seoDescription
      loggedInMessage
      orderHistory
      accountDetails
      updateAccountDetailsSuccesMessage
      updateAccountDetailsFailedMessage
      save
      displayName
      savedArticles
      savedProducts
      personalInfo
      myPreferences
      orders
      savedArticlesPlaceholder
      savedProductsPlaceholder
      showMoreArticles
      showMoreProducts
      orderNumber
      seeOrderDetails
      trackAndTraceCode
      notLoggedInMessage
      notLoggedInButtonText
      notLoggedInLink {
        slug
      }
      bannerTitle
      bannerImage {
        title
        fluid(maxWidth: 1280) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      step1Message
      step3Message
      accountReadyMessage
      userPreferences {
        slideCategories {
          ... on ContentfulModularPage {
            slug
            title
            heroImageMobile {
              fluid(maxWidth: 200) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
        backgroundColors
        title
      }
    }
  }
`

export default Account
