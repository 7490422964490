import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { mobileVW, colors, desktopBreakpoint, font } from '../../styles'

// Hooks
import useAuth from '../../hooks/useAuth'

// Components
import Image from 'gatsby-image'
import Label from '../shared/uiComponents/label'
import Input from '../shared/uiComponents/input'
import Button from '../shared/uiComponents/button'

const Wrapper = styled.div`
  p {
    text-align: center;
    font-family: ${font.ben};
    text-transform: lowercase;
    color: ${colors.brownDarkest};
    margin-bottom: ${mobileVW(13)};
    font-size: ${mobileVW(24)};
    line-height: ${mobileVW(27)};
    font-weight: normal;
    @media (min-width: ${desktopBreakpoint}) {
      margin: 40px auto 28px;
      font-size: 24px;
      line-height: 27px;
    }
  }

  .button_wrapper {
    margin-top: ${mobileVW(75)};
    min-width: ${mobileVW(200)};
    font-family: ${font.ben};
    font-size: ${mobileVW(18)};
    text-transform: lowercase;
    font-weight: normal;
    @media (min-width: ${desktopBreakpoint}) {
      margin-top: 35px;
      min-width: 200px;
      font-size: 18px;
    }
  }
`

const Container = styled.div`
  padding: ${mobileVW(33)} ${mobileVW(16)} ${mobileVW(75)};

  .invoicing_section {
    margin-top: ${mobileVW(35)};
    display: ${props => (props.hide ? 'none' : 'block')};
    @media (min-width: ${desktopBreakpoint}) {
      margin-top: 35px;
    }
  }

  .step2_title {
    @media (min-width: ${desktopBreakpoint}) {
      margin-top: 60px;
      padding: 0 20px;
      font-size: 24px !important;
      line-height: 27px !important;
    }
    p {
      font-family: ${font.larsseit};
      @media (min-width: ${desktopBreakpoint}) {
        margin: 10px auto 20px;
        font-size: 16px !important;
        line-height: 22px !important;
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    max-height: 100%;
    overflow-y: scroll;
    padding: 0 32px 90px;
  }
`

const Grid = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: grid;
    grid-template-columns: 620px 1fr;
    height: 100vh;
  }
`

const Banner = styled.div`
  height: ${mobileVW(380)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    height: 100%;
    width: 100%;
  }

  h2 {
    font-family: ${font.ben};
    font-size: ${mobileVW(58)};
    line-height: 1;
    padding: 0 ${mobileVW(70)};
    text-align: center;
    color: ${colors.beigeUltraLight};
    z-index: 1;
    text-transform: lowercase;
    font-weight: normal;
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 100px;
      padding: 0;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    height: 100vh;
    max-height: 1200px;
  }
`

const Steps = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    max-width: 450px;
    margin: auto;
  }
`

const Step1 = styled.div`
  height: 100%;
  .prev_button {
    visibility: hidden;
  }

  a {
    text-decoration: underline;
  }
`

const AccountInfo = styled.div`
  text-align: center;
  font-family: ${font.ben};
  text-transform: lowercase;
  font-weight: normal;

  p {
    font-size: ${mobileVW(28)};
    line-height: ${mobileVW(31)};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 28px;
      line-height: 31px;
    }
  }
`

const TextWrapper = styled.div`
  display: none;
`

const Group = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: ${props =>
    props.alignStart ? 'flex-start' : 'space-between'};
  margin-top: ${props => (props.marginTop ? `${mobileVW(18)}` : null)};
  align-items: ${props => (props.alignTop ? 'flex-start' : 'center')};

  .privacy_label {
    label {
      color: ${colors.brownDarkest};
      @media (min-width: ${desktopBreakpoint}) {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${props => (props.marginTop ? `18px` : null)};

    label {
      font-size: 12px;
      line-height: 16px;
    }

    input[type='checkbox'] {
      margin-right: 8px;
    }
  }

  div:first-of-type {
    margin-right: ${mobileVW(8)};
    @media (min-width: ${desktopBreakpoint}) {
      margin-right: 7px;
    }
  }
`

const Step3 = styled.div`
  font-family: ${font.larsseit};
  height: 100%;
  padding: ${mobileVW(35)} 0;

  p {
    color: ${colors.brownDarkest};
    margin-bottom: ${mobileVW(13)};
    @media (min-width: ${desktopBreakpoint}) {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;

    label {
      font-size: 14px;
    }
  }
`

const Step2 = styled.div`
  height: 100%;
  min-height: 60vh;
  margin-bottom: ${mobileVW(50)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 35px;
  }
  p {
    font-size: ${mobileVW(14)};
    margin-bottom: 0;
    color: ${props =>
      props.error ? `${colors.orangeNew}` : `${colors.brownDarkest}`};
    @media (min-width: ${desktopBreakpoint}) {
      font-size: 22px;
    }
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(24)};
  line-height: ${mobileVW(27)};
  padding: ${mobileVW(31)} ${mobileVW(31)} ${mobileVW(34)};
  text-align: center;
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 58px !important;
    line-height: 1 !important;
    padding: 0;
  }
`

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${mobileVW(16)};
  grid-row-gap: ${mobileVW(16)};
  @media (min-width: ${desktopBreakpoint}) {
    grid-column-gap: 15px;
    grid-row-gap: 16px;
    margin: auto;
    padding: 0 !important;
  }
`

const TiledSelection = styled.div`
  position: relative;
  border-radius: ${mobileVW(6)};
  overflow: hidden;
  width: ${mobileVW(164)};
  height: ${mobileVW(164)};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${desktopBreakpoint}) {
    border-radius: 12px;
    width: 190px;
    height: 190px;
  }

  &.selected {
    border-radius: ${mobileVW(12)};
    border: solid ${mobileVW(2)} ${colors.brownDark};

    @media (min-width: ${desktopBreakpoint}) {
      border-radius: 12px;
      border: solid 2px ${colors.brownDark};
    }
    .gatsby-image-wrapper {
      width: 90%;
      height: 90%;
      border-radius: ${mobileVW(6)};
      @media (min-width: ${desktopBreakpoint}) {
        border-radius: 6px;
      }
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
`

const TileTitle = styled.div`
  font-family: ${font.ben};
  font-size: ${mobileVW(24)};
  line-height: ${mobileVW(27)};
  color: ${colors.beigeUltraLight};
  font-weight: 600;
  position: absolute;
  pointer-events: none;
  text-align: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 24px;
    line-height: 27px;
  }
`

const AllSteps = ({
  message,
  message2,
  userPreferences,
  banner,
  bannerTitle,
}) => {
  const { user } = useAuth()

  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [birthday, setBirthday] = useState('')
  const [phone, setPhone] = useState('')
  const [showWarnings, setShowWarnings] = useState(false)
  const [shippingStreet, setShippingStreet] = useState('')
  const [shippingNumber, setShippingNumber] = useState('')
  const [shippingCity, setShippingCity] = useState('')
  const [shippingZip, setShippingZip] = useState('')
  const [invoicingStreet, setInvoicingStreet] = useState('')
  const [invoicingNumber, setInvoicingNumber] = useState('')
  const [invoicingCity, setInvoicingCity] = useState('')
  const [invoicingZip, setInvoicingZip] = useState('')
  const [sameAsShippingAddress, setSameAsShippingAddress] = useState(true)
  const [learnPreferences, setLearnPreferences] = useState([])
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [advertisingDisclaimer, setAdvertisingDisclaimer] = useState(false)
  const [privacyDisclaimer, setPrivacyDisclaimer] = useState(false)

  let validation =
    name !== '' && lastName !== '' && learnPreferences.length > 0 && acceptTerms

  useEffect(() => {
    validation =
      name !== '' &&
      lastName !== '' &&
      learnPreferences.length > 0 &&
      acceptTerms
  }, [name, lastName, learnPreferences, acceptTerms])

  const dbUser = firebase.firestore().collection('users')
  useEffect(() => {
    dbUser
      .doc(user.uid)
      .collection('userInfo')
      .doc('info')
      .onSnapshot(doc => {
        let userInfo = doc.data()
        userInfo &&
          (setLastName(userInfo.lastName || ''),
          setName(userInfo.name || ''),
          setBirthday(userInfo.birthday || ''),
          setPhone(userInfo.phone || ''),
          setShippingStreet(userInfo.shippingStreet || ''),
          setSameAsShippingAddress(userInfo.sameAsShippingAddress || ''),
          setShippingNumber(userInfo.shippingNumber || ''),
          setShippingCity(userInfo.shippingCity || ''),
          setShippingZip(userInfo.shippingZip || ''),
          setInvoicingStreet(userInfo.invoicingStreet || ''),
          setInvoicingNumber(userInfo.invoicingNumber || ''),
          setInvoicingCity(userInfo.invoicingCity || ''),
          setInvoicingZip(userInfo.invoicingZip || ''),
          setLearnPreferences(userInfo.learnPreferences || ''),
          setAdvertisingDisclaimer(userInfo.advertisingDisclaimer || ''),
          setPrivacyDisclaimer(userInfo.privacyDisclaimer || ''))
      })
  }, [])

  //Update user details
  const updateUserInfo = () => {
    dbUser
      .doc(user.uid)
      .collection('userInfo')
      .doc('info')
      .set(
        {
          name,
          lastName,
          birthday,
          phone,
          acceptTerms,
          privacyDisclaimer,
          advertisingDisclaimer,
          shippingStreet,
          shippingNumber,
          shippingZip,
          shippingCity,
          invoicingStreet,
          invoicingNumber,
          invoicingZip,
          invoicingCity,
          learnPreferences: learnPreferences,
          registrationComplete: true,
          sameAsShippingAddress,
        },
        { merge: true },
      )
      .catch(e => console.log(e))
  }

  const warningMessages = () => {
    setShowWarnings(true)
  }

  const signUp = () => {
    if (validation) {
      updateUserInfo()
    } else {
      warningMessages()
    }
  }

  const savedCategories = userPreferences.slideCategories

  const title = userPreferences.title

  const backgroundColors = userPreferences.backgroundColors

  const removeSelection = selection => {
    let array = [...learnPreferences] // make a separate copy of the array
    var index = array.indexOf(selection)
    if (index !== -1) {
      array.splice(index, 1)
      setLearnPreferences(array)
    }
  }

  const handleSelection = (e, slide) => {
    e.target.classList.toggle('selected')
    if (e.target.classList.contains('selected')) {
      setLearnPreferences([...learnPreferences, slide.title])
    } else {
      removeSelection(slide.title)
    }
  }

  return (
    <Wrapper>
      <Grid>
        <Banner>
          <Image fluid={banner.fluid} />
          <Title>{bannerTitle}</Title>
        </Banner>
        <Container hide={sameAsShippingAddress}>
          <Steps>
            <Step1>
              <TextWrapper>
                <h2>let's get to know each other</h2>
                <p>we will just take 2 minutes of your time, promise</p>
              </TextWrapper>
              <AccountInfo>
                <p>{message}</p>
                <Group>
                  <Label
                    html_for={'full_name'}
                    labelText={'Naam'}
                    hideMobile
                    hideDesktop
                  />
                  <Input
                    name={'full_name'}
                    type={'text'}
                    value={name ? `${name}` : ''}
                    placeholder={'Voornaam'}
                    onChange={e => setName(e.target.value)}
                    border={showWarnings && name === ''}
                    hideIcon
                  />
                  <Label
                    html_for={'preferred_lastName'}
                    labelText={'Gebruiksnaam*'}
                    hideMobile
                    hideDesktop
                  />
                  <Input
                    name={'preferred_lastName'}
                    type={'text'}
                    placeholder={'Achternaam'}
                    value={lastName ? `${lastName}` : ''}
                    onChange={e => setLastName(e.target.value)}
                    border={showWarnings && lastName === ''}
                    hideIcon
                  />
                </Group>
                <Label
                  html_for={'geboortedatum'}
                  labelText={'Geboordedatum*'}
                  hideMobile
                  hideDesktop
                />
                <Input
                  name={'geboortedatum'}
                  placeholder={'DD / MM / YYYY'}
                  customPlaceholder={'Geboortedatum'}
                  value={birthday ? `${birthday}` : ''}
                  onChange={e => setBirthday(e.target.value)}
                  onFocus={e => (e.target.type = 'date')}
                  hideIcon
                />
                <Label
                  html_for={'phone'}
                  labelText={'Telefoonnummer*'}
                  hideMobile
                  hideDesktop
                />
                <Input
                  name={'phone'}
                  type={'tel'}
                  isPhoneNumber
                  placeholder={'Telefoonnummer'}
                  value={phone ? `${phone}` : ''}
                  onChange={e => setPhone(e.target.value)}
                  hideIcon
                />
              </AccountInfo>
            </Step1>
            <Step3>
              <p>verzendgegevens</p>
              <TextWrapper>
                <h2>in case you want to treat yourself</h2>
                <p>in a hurry?skip and fill these in later...</p>
              </TextWrapper>
              <Group>
                <Input
                  placeholder={'Adres'}
                  value={shippingStreet ? `${shippingStreet}` : ''}
                  type={'text'}
                  stretch
                  onChange={e => setShippingStreet(e.target.value)}
                  hideIcon
                />
                <Input
                  placeholder={'NR'}
                  value={shippingNumber ? `${shippingNumber}` : ''}
                  type={'text'}
                  onChange={e => setShippingNumber(e.target.value)}
                  hideIcon
                  maxWidth={'20%'}
                />
              </Group>
              <Group>
                <Input
                  placeholder={'postcode'}
                  value={shippingZip ? `${shippingZip}` : ''}
                  type={'text'}
                  onChange={e => setShippingZip(e.target.value)}
                  hideIcon
                />
                <Input
                  placeholder={'Stad en land'}
                  value={shippingCity ? `${shippingCity}` : ''}
                  type={'text'}
                  onChange={e => setShippingCity(e.target.value)}
                  hideIcon
                />
              </Group>
              <Group marginTop alignStart>
                <Input
                  type={'checkbox'}
                  name={'factuuradress_hetzelfde'}
                  id={'factuuradress_hetzelfde'}
                  checked={sameAsShippingAddress}
                  onChange={e => setSameAsShippingAddress(e.target.checked)}
                  hideIcon
                />
                <Label
                  htmlFor={'factuuradress_hetzelfde'}
                  labelText={'Factuuradress is hetzelfde'}
                  uppercase
                />
              </Group>
              <div className='invoicing_section'>
                <p>Factuuradres:</p>
                <Group>
                  <Input
                    placeholder={'Streetname'}
                    value={invoicingStreet ? `${invoicingStreet}` : ''}
                    type={'text'}
                    stretch
                    onChange={e => setInvoicingStreet(e.target.value)}
                    hideIcon
                  />
                  <Input
                    placeholder={'NR'}
                    value={invoicingNumber ? `${invoicingNumber}` : ''}
                    type={'text'}
                    onChange={e => setInvoicingNumber(e.target.value)}
                    hideIcon
                    maxWidth={'20%'}
                  />
                </Group>
                <Input
                  placeholder={'Postalcode'}
                  value={invoicingZip ? `${invoicingZip}` : ''}
                  type={'text'}
                  onChange={e => setInvoicingZip(e.target.value)}
                  hideIcon
                />
                <Input
                  placeholder={'City'}
                  value={invoicingCity ? `${invoicingCity}` : ''}
                  type={'text'}
                  onChange={e => setInvoicingCity(e.target.value)}
                  hideIcon
                />
              </div>
            </Step3>
            <Step2
              colors={backgroundColors}
              gradient={backgroundColors[1] ? true : false}
              id='voorkeuren'
              error={showWarnings && learnPreferences.length < 1}
            >
              <Title className='step2_title'>
                {title}
                <p>Choose at least one...</p>
              </Title>
              <Gallery className='step2_gallery'>
                {savedCategories.map((slide, i) => (
                  <TiledSelection
                    key={i}
                    onClick={e => handleSelection(e, slide)}
                    className={
                      learnPreferences?.indexOf(`${slide.title}`) !== -1
                        ? 'selected'
                        : null
                    }
                  >
                    <Image
                      fluid={
                        slide.thumbnail?.fluid || slide.heroImageMobile?.fluid
                      }
                    />
                    <TileTitle>{slide.title}</TileTitle>
                  </TiledSelection>
                ))}
              </Gallery>
            </Step2>
            <Group marginTop alignTop>
              <Input
                type={'checkbox'}
                name={'online_advertenties'}
                id={'advertenties_disclaimer'}
                checked={advertisingDisclaimer}
                onChange={e => setAdvertisingDisclaimer(e.target.checked)}
                hideIcon
              />
              <Label
                htmlFor={'online_advertenties'}
                disclaimer
                labelText={
                  'We gebruiken e-mail en gerichte online advertenties om u product- en diensten updates, promotionele aanbiedingen en andere marketingcommunicatie te sturen op basis van de informatie die wij over u verzamelen, zoals uw e-mailadres, algemene locatie en aankoop- en website geschiedenis.'
                }
              />
            </Group>
            <Group marginTop alignTop>
              <Input
                type={'checkbox'}
                name={'persoonlijke_gegevens'}
                id={'privacy_disclaimer'}
                checked={privacyDisclaimer}
                onChange={e => setPrivacyDisclaimer(e.target.checked)}
                hideIcon
              />
              <Label
                htmlFor={'persoonlijke_gegevens'}
                disclaimer
                labelText={
                  'Wij verwerken uw persoonlijke gegevens zoals vermeld in ons privacybeleid. U kunt uw toestemming te allen tijde intrekken of uw voorkeuren beheren door op de afmeld link onderaan onze marketing e-mails te klikken of door ons een e-mail te sturen naar privacy@cycle.care.'
                }
              />
            </Group>
            <Group marginTop alignTop>
              <Input
                type={'checkbox'}
                name={'accept_terms'}
                id={'terms_checkbox'}
                border={showWarnings && acceptTerms === false}
                onChange={e => setAcceptTerms(e.target.checked)}
                hideIcon
              />
              <div className='privacy_label'>
                <Label
                  htmlFor='terms_checkbox'
                  labelText={[
                    'Ik accepteer de ',
                    <a href={'/nl/gebruikersvoorwaarden'}>
                      Service Voorwaarden{' '}
                    </a>,
                    'en het ',
                    <a href={'/nl/privacy-statement-en-cookies'}>
                      Privacybeleid
                    </a>,
                    <span> (verplicht)</span>,
                  ]}
                ></Label>
              </div>
            </Group>
            <Button
              ctaType={'primary'}
              cta={() => signUp()}
              text={'join cycle'}
            ></Button>
          </Steps>
        </Container>
      </Grid>
    </Wrapper>
  )
}

export default AllSteps
