import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useAuth from '../../hooks/useAuth'
import firebase from 'firebase/app'
import 'firebase/firestore'
import Input from '../../components/shared/uiComponents/input'
import Label from '../shared/uiComponents/label'
import Button from '../shared/uiComponents/button'

import { mobileVW, colors, desktopBreakpoint, font } from '../../styles'

const StyledAccountDetails = styled.section`
  font-family: ${font.ben};
  font-size: ${mobileVW(30)};
  display: block;
  color: ${colors.brownDarkest};
  padding: 0 ${mobileVW(16)};
  text-align: center;
  text-transform: lowercase;
  font-weight: normal;

  legend {
    margin-bottom: ${mobileVW(23)};
    text-transform: lowercase;
    text-align: center;
    font-size: ${mobileVW(24)};
    line-height: ${mobileVW(27)};
    font-family: ${font.ben};
    font-weight: normal;
    @media (min-width: ${desktopBreakpoint}) {
      display: block;
      font-size: 24px;
      line-height: 27px;
      color: ${colors.brownDarkest};
      margin-bottom: 20px;
    }
  }

  label {
    text-align: left;
  }

  p {
    font-size: ${mobileVW(36)};
    line-height: ${mobileVW(39)};
    margin-bottom: ${mobileVW(35)};
    @media (min-width: ${desktopBreakpoint}) {
      display: none;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 40px;
    text-align: center;
    padding: 0;
    max-width: 1280px;
    margin: 0 auto 0 40px;
  }
`

const StyledForm = styled.form``

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${desktopBreakpoint}) {
    display: grid;
    grid-template-columns: 343px 343px;
    grid-column-gap: 30px;
    margin-top: 40px;
    input {
      margin-top: 18px;
    }
  }
`

const PostMessage = styled.div`
  font-size: ${mobileVW(13)};
  margin: ${mobileVW(20)} auto;
  background: none !important;
  width: 100%;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 17px;
    margin: 20px 0;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  legend {
    display: none;
    @media (min-width: ${desktopBreakpoint}) {
      display: block;
    }
  }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: flex-start;
  justify-content: flex-start;
  margin: ${mobileVW(30)} auto 0 0;

  > div {
    background: ${props => (props.changed ? `${colors.orangeLight}` : null)};
    color: ${props => (props.changed ? `${colors.brownDarkest}` : null)};
    min-width: ${mobileVW(200)};
    @media (min-width: ${desktopBreakpoint}) {
      min-width: 300px;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    margin: 60px auto 0 0;
  }
`

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin-top: ${mobileVW(35)};
  display: ${props => (props.hide ? 'none' : 'block')};
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 0;
    &:not(:first-of-type) {
      margin-top: 30px;
    }
  }
`

const Group = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  margin-top: ${props => (props.marginTop ? `${mobileVW(18)}` : null)};
  align-items: ${props => (props.alignTop ? 'flex-start' : 'center')};

  .privacy_label {
    label {
      color: ${colors.brownDarkest};
      @media (min-width: ${desktopBreakpoint}) {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${props => (props.marginTop ? `18px` : null)};

    label {
      font-size: 12px;
      line-height: 16px;
    }

    input[type='checkbox'] {
      margin-right: 8px;
    }
  }

  div:first-of-type {
    margin-right: ${mobileVW(8)};
    @media (min-width: ${desktopBreakpoint}) {
      margin-right: 7px;
    }
  }
`

const AccountDetails = ({
  updateAccountDetailsSuccesMessage,
  updateAccountDetailsFailedMessage,
  save,
}) => {
  const { user } = useAuth()
  const [currentLastName, setCurrentLastName] = useState('')
  const [newLastName, setNewLastName] = useState(null)
  const [currentName, setCurrentName] = useState('')
  const [newName, setNewName] = useState(null)
  const [currentBirthday, setCurrentBirthday] = useState('')
  const [newBirthday, setNewBirthday] = useState(null)

  const [currentShippingZip, setCurrentShippingZip] = useState(null)
  const [currentShippingStreet, setCurrentShippingStreet] = useState(null)
  const [currentShippingNumber, setCurrentShippingNumber] = useState(null)
  const [currentShippingCity, setCurrentShippingCity] = useState(null)

  const [currentInvoicingZip, setCurrentInvoicingZip] = useState(null)
  const [currentInvoicingStreet, setCurrentInvoicingStreet] = useState(null)
  const [currentInvoicingNumber, setCurrentInvoicingNumber] = useState(null)
  const [currentInvoicingCity, setCurrentInvoicingCity] = useState(null)

  const [newShippingZip, setNewShippingZip] = useState(null)
  const [newShippingStreet, setNewShippingStreet] = useState(null)
  const [newShippingNumber, setNewShippingNumber] = useState(null)
  const [newShippingCity, setNewShippingCity] = useState(null)

  const [newInvoicingZip, setNewInvoicingZip] = useState(null)
  const [newInvoicingStreet, setNewInvoicingStreet] = useState(null)
  const [newInvoicingNumber, setNewInvoicingNumber] = useState(null)
  const [newInvoicingCity, setNewInvoicingCity] = useState(null)

  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('')
  const [newPhoneNumber, setNewPhoneNumber] = useState(null)
  const [updatedAccount, setUpdatedAccount] = useState()

  const [sameAsShippingAddress, setSameAsShippingAddress] = useState(true)

  const [changes, setChanges] = useState(false)

  const dbUser = firebase.firestore().collection('users')
  useEffect(() => {
    dbUser
      .doc(user.uid)
      .collection('userInfo')
      .doc('info')
      .onSnapshot(doc => {
        let userInfo = doc.data()
        userInfo &&
          (setCurrentLastName(userInfo.lastName || ''),
          setCurrentName(userInfo.name || ''),
          setCurrentBirthday(userInfo.birthday || ''),
          setCurrentShippingCity(userInfo.shippingCity || ''),
          setCurrentShippingNumber(userInfo.shippingNumber || ''),
          setCurrentShippingStreet(userInfo.shippingStreet || ''),
          setCurrentShippingZip(userInfo.shippingZip || ''),
          setCurrentInvoicingCity(userInfo.invoicingCity || ''),
          setCurrentInvoicingStreet(userInfo.invoicingStreet || ''),
          setCurrentInvoicingNumber(userInfo.invoicingNumber || ''),
          setCurrentInvoicingZip(userInfo.invoicingZip || ''),
          setSameAsShippingAddress(userInfo.sameAsShippingAddress || ''),
          setCurrentPhoneNumber(userInfo.phone || ''))
      })
  }, [])

  //Update user details
  const updateUserInfo = e => {
    e.preventDefault()
    console.log('updating info')
    dbUser
      .doc(user.uid)
      .collection('userInfo')
      .doc('info')
      .set(
        {
          name: newName !== null ? newName : currentName,
          lastName: newLastName !== null ? newLastName : currentLastName,
          birthday: newBirthday !== null ? newBirthday : currentBirthday,
          shippingStreet:
            newShippingStreet !== null
              ? newShippingStreet
              : currentShippingStreet,
          shippingNumber:
            newShippingNumber !== null
              ? newShippingNumber
              : currentShippingNumber,
          shippingZip:
            newShippingZip !== null ? newShippingZip : currentShippingZip,
          shippingCity:
            newShippingCity !== null ? newShippingCity : currentShippingCity,
          invoicingStreet:
            newInvoicingStreet !== null
              ? newInvoicingStreet
              : currentInvoicingStreet,
          invoicingNumber:
            newInvoicingNumber !== null
              ? newInvoicingNumber
              : currentInvoicingNumber,
          invoicingZip:
            newInvoicingZip !== null ? newInvoicingZip : currentInvoicingZip,
          invoicingCity:
            newInvoicingCity !== null ? newInvoicingCity : currentInvoicingCity,
          phone: newPhoneNumber !== null ? newPhoneNumber : currentPhoneNumber,
          sameAsShippingAddress,
        },
        { merge: true },
      )
      .then(function() {
        setUpdatedAccount(true)
      })
      .catch(function(error) {
        setUpdatedAccount(false)
        console.log('failed', error)
      })
  }

  const updateState = (state, e) => {
    state(e.target.value)
    e.target.value !== '' && e.target.value !== e.target.placeholder
      ? setChanges(true)
      : setChanges(false)
  }

  return (
    <StyledAccountDetails id='gevegens'>
      <p>persoonlijke gegevens</p>
      <StyledForm onSubmit={e => updateUserInfo(e)}>
        <Grid>
          <Left>
            <legend>persoonlijke gegevens</legend>
            <Group>
              <Label
                html_for={'current_name'}
                labelText={'Naam'}
                hideMobile
                hideDesktop
              />
              <Input
                name={'current_name'}
                labelText={'Naam'}
                type={'text'}
                customPlaceholder={`${currentName}` ? `Naam` : false}
                placeholder={`${currentName}` ? `${currentName}` : 'Naam'}
                onChange={e => updateState(setNewName, e)}
                hideIcon
              />
              <Label
                html_for={'current_lastName'}
                labelText={'Achternaam'}
                hideMobile
                hideDesktop
              />
              <Input
                name={'current_lastName'}
                labelText={'Achternaam'}
                type={'text'}
                customPlaceholder={`${currentLastName}` ? `Achternaam` : false}
                placeholder={
                  `${currentLastName}` ? `${currentLastName}` : 'Achternaam'
                }
                onChange={e => updateState(setNewLastName, e)}
                hideIcon
              />
            </Group>
            <Label
              html_for={'current_birthday'}
              labelText={'Geboortedatum'}
              hideMobile
              hideDesktop
            />
            <Input
              name={'current_birthday'}
              labelText={'Geboortedatum'}
              type={'text'}
              customPlaceholder={`${currentBirthday}` ? `Geboortedatum` : false}
              placeholder={
                `${currentBirthday}` ? `${currentBirthday}` : 'Geboortedatum'
              }
              onChange={e => updateState(setNewBirthday, e)}
              onFocus={e => (e.target.type = 'date')}
              hideIcon
            />
            <Label
              html_for={'current_phone'}
              labelText={'Telefoonnummer'}
              hideMobile
              hideDesktop
            />
            <Input
              name={'current_phone'}
              labelText={'Telefoonnummer'}
              type='tel'
              customPlaceholder={
                `${currentPhoneNumber}` ? `Telefoonnummer` : false
              }
              placeholder={
                `${currentPhoneNumber}`
                  ? `${currentPhoneNumber}`
                  : 'Telefoonnummer'
              }
              onChange={e => updateState(setNewPhoneNumber, e)}
              hideIcon
            />
          </Left>
          <Right>
            <Fieldset>
              <legend>verzendgegevens</legend>
              <Group>
                <Label
                  html_for={'current_shipping_street'}
                  labelText={'Shipping street'}
                  hideMobile
                  hideDesktop
                />
                <Input
                  name={'current_shipping_street'}
                  type={'text'}
                  stretch
                  customPlaceholder={
                    currentShippingStreet && currentShippingStreet !== ''
                      ? `Streetname and number`
                      : false
                  }
                  placeholder={
                    `${currentShippingStreet && currentShippingStreet !== ''}`
                      ? `${currentShippingStreet}`
                      : 'Streetname and number'
                  }
                  onChange={e => updateState(setNewShippingStreet, e)}
                  hideIcon
                />
                <Label
                  html_for={'current_shipping_number'}
                  labelText={'Shipping number'}
                  hideMobile
                  hideDesktop
                />
                <Input
                  name={'current_shipping_number'}
                  type={'text'}
                  customPlaceholder={
                    currentShippingNumber && currentShippingNumber !== ''
                      ? `NR`
                      : false
                  }
                  placeholder={
                    `${currentShippingNumber && currentShippingNumber !== ''}`
                      ? `${currentShippingNumber}`
                      : 'NR'
                  }
                  onChange={e => updateState(setNewShippingNumber, e)}
                  hideIcon
                  maxWidth={'20%'}
                />
              </Group>
              <Group>
                <Label
                  html_for={'current_shipping_zip'}
                  labelText={'Shipping zip'}
                  hideMobile
                  hideDesktop
                />
                <Input
                  name={'current_shipping_zip'}
                  type={'text'}
                  customPlaceholder={
                    currentShippingZip && currentShippingZip !== ''
                      ? `Postalcode`
                      : false
                  }
                  placeholder={
                    `${currentShippingZip && currentShippingZip !== ''}`
                      ? `${currentShippingZip}`
                      : 'Postalcode'
                  }
                  onChange={e => updateState(setNewShippingZip, e)}
                  hideIcon
                />
                <Label
                  html_for={'current_shipping_city'}
                  labelText={'Shipping city'}
                  hideMobile
                  hideDesktop
                />
                <Input
                  name={'current_shipping_city'}
                  type={'text'}
                  customPlaceholder={
                    currentShippingCity && currentShippingCity !== ''
                      ? `City`
                      : false
                  }
                  placeholder={
                    `${currentShippingCity && currentShippingCity !== ''}`
                      ? `${currentShippingCity}`
                      : 'City'
                  }
                  onChange={e => updateState(setNewShippingCity, e)}
                  hideIcon
                />
              </Group>
              <Group marginTop>
                <Input
                  type={'checkbox'}
                  name={'factuuradress_hetzelfde'}
                  id={'factuuradress_hetzelfde'}
                  checked={sameAsShippingAddress}
                  onChange={e => setSameAsShippingAddress(e.target.checked)}
                  hideIcon
                />
                <Label
                  htmlFor={'factuuradress_hetzelfde'}
                  labelText={'Factuuradress is hetzelfde'}
                  uppercase
                />
              </Group>
            </Fieldset>
            <Fieldset hide={sameAsShippingAddress}>
              <legend>Factuuradres</legend>
              <Group>
                <Label
                  html_for={'current_invoicing_street'}
                  labelText={'Invoicing street'}
                  hideMobile
                  hideDesktop
                />
                <Input
                  name={'current_invoicing_street'}
                  type={'text'}
                  customPlaceholder={
                    currentInvoicingStreet && currentInvoicingStreet !== ''
                      ? `Streetname`
                      : false
                  }
                  placeholder={
                    `${currentInvoicingStreet && currentInvoicingStreet !== ''}`
                      ? `${currentInvoicingStreet}`
                      : 'Streetname'
                  }
                  onChange={e => updateState(setNewInvoicingStreet, e)}
                  hideIcon
                  stretch
                />
                <Label
                  html_for={'current_invoicing_number'}
                  labelText={'Invoicing Number'}
                  hideMobile
                  hideDesktop
                />
                <Input
                  name={'current_invoicing_number'}
                  type={'text'}
                  customPlaceholder={
                    currentInvoicingNumber && currentInvoicingNumber !== ''
                      ? `NR`
                      : false
                  }
                  placeholder={
                    `${currentInvoicingNumber && currentInvoicingNumber !== ''}`
                      ? `${currentInvoicingNumber}`
                      : 'NR'
                  }
                  maxWidth={'20%'}
                  onChange={e => updateState(setNewInvoicingNumber, e)}
                  hideIcon
                />
              </Group>
              <Group>
                <Label
                  html_for={'current_invoicing_zip'}
                  labelText={'Invoicing zip'}
                  hideMobile
                  hideDesktop
                />
                <Input
                  name={'current_invoicing_zip'}
                  type={'text'}
                  customPlaceholder={
                    currentInvoicingZip && currentInvoicingZip !== ''
                      ? `Postalcode`
                      : false
                  }
                  placeholder={
                    `${currentInvoicingZip && currentInvoicingZip !== ''}`
                      ? `${currentInvoicingZip}`
                      : 'Postalcode'
                  }
                  onChange={e => updateState(setNewInvoicingZip, e)}
                  hideIcon
                />
                <Label
                  html_for={'current_invoicing_city'}
                  labelText={'Invoicing city'}
                  hideMobile
                  hideDesktop
                />
                <Input
                  name={'current_invoicing_city'}
                  type={'text'}
                  customPlaceholder={
                    currentInvoicingCity && currentInvoicingCity !== ''
                      ? `City`
                      : false
                  }
                  placeholder={
                    `${currentInvoicingCity && currentInvoicingCity !== ''}`
                      ? `${currentInvoicingCity}`
                      : 'City'
                  }
                  onChange={e => updateState(setNewInvoicingCity, e)}
                  hideIcon
                />
              </Group>
            </Fieldset>
          </Right>
        </Grid>
        <ButtonWrapper changed={changes}>
          {updatedAccount && (
            <PostMessage>
              {updatedAccount && updateAccountDetailsSuccesMessage}
              {updatedAccount === false && updateAccountDetailsFailedMessage}
            </PostMessage>
          )}
          <Button
            ctaType={'primary'}
            type={'submit'}
            value={'Submit'}
            text={save}
            cta={() => setChanges(false)}
          />
        </ButtonWrapper>
      </StyledForm>
    </StyledAccountDetails>
  )
}

export default AccountDetails
