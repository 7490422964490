import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Image from 'gatsby-image'
import Bag from '../graphics/Bag'
import AccountIcon from '../graphics/AccountIcon'
import SettingsIcon from '../graphics/SettingsIcon'
import Bookmark from '../graphics/Bookmark'

import {
  colors,
  mobileVW,
  letterSpacing,
  font,
  desktopBreakpoint,
  headerHeight,
} from '../../styles'
import { call } from 'ramda'

const Dropdown = styled.div``
const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${mobileVW(16)} 0 ${mobileVW(81)};
  padding: ${mobileVW(25)} 0 ${mobileVW(20)};
  border-bottom: solid ${mobileVW(1)} #e7e7e7;
  position: relative;
  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 16px 0 81px;
    padding: 25px 0 20px;
    border-bottom: solid 1px #e7e7e7;
    cursor: pointer;
  }
`
const MenuContent = styled.div`
  display: ${props => (props.show ? `block` : `none`)};
  margin-top: ${mobileVW(20)};
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 20px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  svg:nth-of-type(2) {
    transform: rotate(180deg);
  }
`
const Icon = styled.div`
  position: absolute;
  left: ${mobileVW(-65)};
  background-color: ${colors.beigeUltraLight};
  border-radius: 50%;
  width: ${mobileVW(40)};
  height: ${mobileVW(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${mobileVW(25)};

  @media (min-width: ${desktopBreakpoint}) {
    left: -65px;
    width: 40px;
    height: 40px;
    margin-right: 25px;
    background-color: ${props =>
      props.active ? `${colors.brownDark}` : ` ${colors.beigeUltraLight}`};
    svg {
      path {
        stroke: ${props =>
          props.active ? `white` : ` ${colors.brownDarkest}`};
      }
    }

    .fill_icon {
      svg {
        path {
          fill: ${props =>
            props.active ? `white` : ` ${colors.brownDarkest}`};
          stroke: none;
        }
      }
    }
  }
`
const Title = styled.div`
  font-family: ${font.ben};
  font-size: ${mobileVW(18)};
  line-height: ${mobileVW(21)};
  color: ${colors.brownDarkest};
  display: flex;
  align-items: center;
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 18px;
    line-height: 21px;
  }
`
const Arrow = styled.div`
  width: ${mobileVW(7)};
  height: ${mobileVW(12)};
  justify-self: flex-end;
  transform: ${props => (props.open ? `rotate(270deg)` : `rotate(90deg)`)};
  transition: transform 0.2s ease-in-out;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.3335L5.66667 6.00016L1 10.6668' stroke='%23C8C8C8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  @media (min-width: ${desktopBreakpoint}) {
    width: 7px;
    height: 12px;
    transform: none;
  }
`

const DropdownMenu = ({ title, children, iconName, callback, active }) => {
  const [openDropdown, setDropdownOpen] = useState(false)

  return (
    <Dropdown>
      <MenuHeader
        onClick={callback ? callback : () => setDropdownOpen(!openDropdown)}
      >
        <Icon active={active}>
          {iconName == 'bag' ? (
            <Bag />
          ) : iconName == 'settings' ? (
            <Wrapper className='fill_icon'>
              <SettingsIcon />
              <SettingsIcon />
            </Wrapper>
          ) : iconName == 'account' ? (
            <div className='fill_icon'>
              <AccountIcon />
            </div>
          ) : iconName == 'bookmark' ? (
            <Bookmark stroke={`${colors.brownDarkest}`} />
          ) : null}
        </Icon>
        <Title>{title}</Title>
        <Arrow open={openDropdown} />
      </MenuHeader>
      {children ? (
        <MenuContent show={openDropdown}>{children}</MenuContent>
      ) : null}
    </Dropdown>
  )
}

export default DropdownMenu
