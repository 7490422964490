import styled from 'styled-components'
import { Link } from 'gatsby'

import {
  mobileVW,
  desktopVW,
  font,
  colors,
  letterSpacing,
  desktopBreakpoint,
} from '../styles'

const Button = styled(Link)`
  display: inline-block;
  border-radius: ${mobileVW(20)};
  font-family: ${font.marby};
  font-size: ${mobileVW(14)};
  letter-spacing: ${letterSpacing(6)};
  text-transform: lowercase;
  border: 1px solid ${props => (props.color ? props.color : colors.orange)};
  padding: ${mobileVW(10)} ${mobileVW(40)} ${mobileVW(14)};
  color: ${props => (props.color ? props.color : '#fff')};
  background: ${props => (props.color ? 'transparent' : colors.orange)};

  @media (min-width: ${desktopBreakpoint}) {
    border-radius: ${desktopVW(30)};
    font-size: ${desktopVW(20)};
    padding: ${desktopVW(10)} ${desktopVW(50)} ${desktopVW(16)};
  }
`

export default Button
