import React from 'react'
import styled from 'styled-components'
import { font, colors, mobileVW, desktopBreakpoint } from '../../styles'

// Hooks
import useSaved from './useSaved'

// Components
import ProductThumbnail from '../collection/ProductThumbnail'
import ArticleProductCard from '../shared/uiComponents/articleProductCard'
import AccountSwiper from './AccountSwiper'
import Button from '../shared/uiComponents/button'

const Container = styled.section`
  padding-top: 30px;
  padding-bottom: 40px;
  width: 100%;
  overflow: hidden;

  .card-text-wrapper {
    p {
      display: none;
    }

    h3 {
      font-size: 28px;
      height: ${mobileVW(63)};

      @media (min-width: ${desktopBreakpoint}) {
        height: auto;
      }
    }
  }

  .product_card {
    .button_wrapper {
      display: none;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: fit-content;
  }
`

const SwiperContainer = styled(Container)`
  .swiper-container {
    margin-left: ${mobileVW(16)};

    @media (min-width: ${desktopBreakpoint}) {
      margin-left: 0;
    }
  }

  h2:not(.thumbnail__title) {
    text-align: center;
    text-transform: lowercase;
    font-size: ${mobileVW(36)};

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 24px;
      margin-bottom: 0;
    }
  }

  .card-text-wrapper {
    p {
      display: none;
    }

    h3 {
      font-size: ${mobileVW(18)};
      height: ${mobileVW(63)};

      @media (min-width: ${desktopBreakpoint}) {
        height: auto;
        font-size: 28px;
      }
    }
  }

  .thumbnail {
    width: ${mobileVW(164)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    .swiper-slide {
      margin-right: 16px;
    }
  }
`

const GalleryContainer = styled(Container)`
  .product_card {
    padding: 0;
    margin: 0;
    min-height: 400px;
  }
`

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 262px));
  grid-column-gap: 21px;
  grid-row-gap: 60px;
  justify-content: center;
  padding-bottom: 48px;

  @media (min-width: 1350px) {
    grid-template-columns: repeat(3, minmax(auto, 262px));
    justify-content: flex-start;
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  font-size: ${mobileVW(30)};
  color: ${colors.brownDarkest};
  margin-bottom: ${mobileVW(32)};
  text-transform: lowercase;
  font-weight: normal;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 40px;
    color: ${colors.brownDarkest};
    margin-bottom: 56px;
  }
`

const Placeholder = styled.p`
  text-align: center;
  font-size: ${mobileVW(16)};
  color: ${colors.brownDarkest};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    text-align: left;
  }
`

const VALID_TYPES = ['product', 'article']

function renderItems(type, items, cb) {
  return items.map((item, i) => {
    switch (type) {
      case 'product':
        return (
          <ProductThumbnail
            {...item}
            fixedSize={true}
            key={item.id}
            onRemoveFavourite={cb}
          />
        )

      case 'article':
        return (
          <ArticleProductCard
            key={item.id}
            content={item}
            onRemoveFavourite={cb}
          />
        )

      default:
        return null
    }
  })
}

const SavedSwiper = ({
  title,
  collection,
  placeholder,
  itemType,
  className,
}) => {
  const { entries, size, next, remove, loading } = useSaved(collection)

  const isValidItem = VALID_TYPES.includes(itemType)

  return (
    <SwiperContainer className={className}>
      <Title>{title}</Title>

      {loading == false && isValidItem && (
        <>
          {entries && Array.isArray(entries) && entries.length > 0 && (
            <AccountSwiper onReachEnd={() => next()}>
              {renderItems(itemType, entries, id => remove(id))}
            </AccountSwiper>
          )}

          {size <= 0 && <Placeholder>{placeholder}</Placeholder>}
        </>
      )}
    </SwiperContainer>
  )
}

const SavedGallery = ({
  collection,
  showMoreLabel,
  placeholder,
  itemType,
  className,
}) => {
  const { entries, size, next, remove, loading } = useSaved(collection, {
    paginate: 3,
  })

  const isValidItem = VALID_TYPES.includes(itemType)

  return (
    <GalleryContainer className={className}>
      {loading == false && isValidItem && (
        <>
          {entries && Array.isArray(entries) && entries.length > 0 && (
            <Gallery>
              {renderItems(itemType, entries, id => remove(id))}
            </Gallery>
          )}

          {size > 0 ? (
            entries.length < size && (
              <Button
                cta={() => next()}
                ctaType={'terciary'}
                text={showMoreLabel}
              />
            )
          ) : (
            <Placeholder>{placeholder}</Placeholder>
          )}
        </>
      )}
    </GalleryContainer>
  )
}

export { SavedGallery, SavedSwiper }
