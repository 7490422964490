import React from 'react'

const SettingsIcon = props => (
  <svg
    width='22'
    height='8'
    viewBox='0 0 22 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.5 3.75C21.5 3.33579 21.1642 3 20.75 3H18.425C18.05 1.275 16.55 0 14.75 0C12.95 0 11.45 1.275 11.075 3H1.25C0.835786 3 0.5 3.33579 0.5 3.75C0.5 4.16421 0.835786 4.5 1.25 4.5H11.075C11.45 6.225 12.95 7.5 14.75 7.5C16.55 7.5 18.05 6.225 18.425 4.5H20.75C21.1642 4.5 21.5 4.16421 21.5 3.75ZM14.75 6C13.475 6 12.5 5.025 12.5 3.75C12.5 2.475 13.475 1.5 14.75 1.5C16.025 1.5 17 2.475 17 3.75C17 5.025 16.025 6 14.75 6Z'
      fill='black'
    />
  </svg>
)

export default SettingsIcon
