import React from 'react'
import styled from 'styled-components'

import { mobileVW, colors, desktopBreakpoint, font } from '../../../styles'

const StyledLabel = styled.label`
  font-family: ${font.larsseit};
  display: ${props => (props.hideMobile ? `none` : `block`)};
  font-size: ${props =>
    props.smallCopy ? `${mobileVW(12)}` : `${mobileVW(15)}`};
  line-height: ${props =>
    props.smallCopy ? `${mobileVW(16)}` : `${mobileVW(19)}`};
  text-transform: ${props => (props.uppercase ? `uppercase` : `none`)};
  color: ${props =>
    props.dark ? `${colors.brownDarkest}` : `${colors.brownDark}`};

  a {
    text-decoration: underline;
  }

  span {
    color: ${colors.orangeNew};
  }

  &:not(:first-of-type) {
    margin-top: ${mobileVW(30)};
    @media (min-width: ${desktopBreakpoint}) {
      margin-top: 30px;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    display: ${props => (props.hideDesktop ? `none` : `block`)};
    font-size: 20px;
    line-height: 20.8px;
  }
`
const Label = ({
  html_for,
  labelText,
  hideMobile,
  hideDesktop,
  uppercase,
  disclaimer,
  darker,
}) => {
  return (
    <StyledLabel
      hideMobile={hideMobile}
      hideDesktop={hideDesktop}
      htmlFor={html_for}
      uppercase={uppercase}
      smallCopy={disclaimer}
      dark={darker}
    >
      {labelText}
    </StyledLabel>
  )
}

export default Label
