import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import Navigation from './Navigation'
import firebase from 'firebase/app'
import 'firebase/firestore'
import useAuth from '../../hooks/useAuth'
import Button from '../shared/uiComponents/button'

import { mobileVW, colors, desktopBreakpoint, font } from '../../styles'

const Wrapper = styled.div`
  height: 100%;
  min-height: 60vh;

  p {
    display: none;
    @media (min-width: ${desktopBreakpoint}) {
      display: block;
      font-family: ${font.larsseit};
      color: ${colors.brownDarkest};
      font-size: 20px;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 0 49px 0;
    margin-left: 40px;
  }
`

const Title = styled.h2`
  font-family: ${font.ben};
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(24)};
  line-height: ${mobileVW(27)};
  padding: ${mobileVW(31)} ${mobileVW(31)} ${mobileVW(34)};
  text-align: center;
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 24px;
    line-height: 1;
    padding: 0 40px 35px;
  }
`

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${mobileVW(16)};
  grid-row-gap: ${mobileVW(16)};
  padding: ${mobileVW(0)} ${mobileVW(14)} ${mobileVW(54)} ${mobileVW(17)};
  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    margin: auto;
    grid-template-columns: 164px 164px 164px 164px;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
`

const TiledSelection = styled.div`
  position: relative;
  border-radius: ${mobileVW(6)};
  overflow: hidden;
  width: ${mobileVW(164)};
  height: ${mobileVW(164)};
  display: flex;
  align-items: center;
  justify-content: center;

  &.selected {
    border-radius: ${mobileVW(12)};
    border: solid ${mobileVW(2)} ${colors.brownDark};

    @media (min-width: ${desktopBreakpoint}) {
      border-radius: 12px;
      border: solid 2px ${colors.brownDark};
    }
    .gatsby-image-wrapper {
      width: 90%;
      height: 90%;
      border-radius: ${mobileVW(6)};
      @media (min-width: ${desktopBreakpoint}) {
        border-radius: 6px;
      }
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    border-radius: 12px;
    width: 164px;
    height: 164px;
  }
`

const TileTitle = styled.div`
  font-family: ${font.ben};
  font-size: ${mobileVW(24)};
  line-height: ${mobileVW(27)};
  color: ${colors.beigeUltraLight};
  position: absolute;
  pointer-events: none;
  text-align: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 18px;
  }
`

const PostMessage = styled.div`
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(13)};
  text-align: center;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 17px;
    padding-top: 20px;
  }
`

const ButtonWrapper = styled.div`
  padding: ${mobileVW(20)} 0 ${mobileVW(54)};
  @media (min-width: ${desktopBreakpoint}) {
    padding: 45px 0 54px;
  }
`

const Step2 = ({
  customTitle,
  overviewPage,
  prevStep,
  nextStep,
  updateAccountDetailsSuccesMessage,
  updateAccountDetailsFailedMessage,
  userPreferences,
}) => {
  const { user } = useAuth()

  const [learnPreferences, setLearnPreferences] = useState([])
  const [updatedAccount, setUpdatedAccount] = useState()

  const dbUser = firebase.firestore().collection('users')

  let validation = learnPreferences.length > 0

  useEffect(() => {
    learnPreferences.length > 0
  }, [learnPreferences])

  useEffect(() => {
    dbUser
      .doc(user.uid)
      .collection('userInfo')
      .doc('info')
      .onSnapshot(doc => {
        let userInfo = doc.data()
        userInfo && setLearnPreferences(userInfo.learnPreferences || '')
      })
  }, [])

  //Update user details
  const updateUserInfo = () => {
    dbUser
      .doc(user.uid)
      .collection('userInfo')
      .doc('info')
      .set(
        {
          learnPreferences: learnPreferences,
        },
        { merge: true },
      )
      .then(function() {
        setUpdatedAccount(true)
      })
      .catch(function(error) {
        setUpdatedAccount(false)
        console.log('failed', error)
      })
  }

  const savedCategories = userPreferences.slideCategories

  const title = userPreferences.title

  const backgroundColors = userPreferences.backgroundColors

  const removeSelection = selection => {
    let array = [...learnPreferences] // make a separate copy of the array
    var index = array.indexOf(selection)
    if (index !== -1) {
      array.splice(index, 1)
      setLearnPreferences(array)
    }
  }

  const handleSelection = (e, slide) => {
    e.target.classList.toggle('selected')
    if (e.target.classList.contains('selected')) {
      setLearnPreferences([...learnPreferences, slide.title])
    } else {
      removeSelection(slide.title)
    }
  }

  return (
    <>
      <Wrapper
        colors={backgroundColors}
        gradient={backgroundColors[1] ? true : false}
        id='voorkeuren'
      >
        <Title className='step2_title'>
          {customTitle ? customTitle : title}
          {overviewPage ? null : <p>Choose at least one...</p>}
        </Title>
        <Gallery lessPadding={overviewPage} className='step2_gallery'>
          {savedCategories.map(slide => (
            <TiledSelection
              key={slide.title}
              onClick={e => handleSelection(e, slide)}
              biggerImages={overviewPage}
              className={
                learnPreferences?.indexOf(`${slide.title}`) !== -1
                  ? 'selected'
                  : null
              }
            >
              <Image
                fluid={slide.thumbnail?.fluid || slide.heroImageMobile?.fluid}
              />
              <TileTitle>{slide.title}</TileTitle>
            </TiledSelection>
          ))}
        </Gallery>
        {overviewPage && updatedAccount && (
          <PostMessage>
            {updatedAccount && updateAccountDetailsSuccesMessage}
            {updatedAccount === false && updateAccountDetailsFailedMessage}
          </PostMessage>
        )}
        {overviewPage ? (
          <ButtonWrapper>
            <Button
              ctaType={'primary'}
              cta={() => updateUserInfo()}
              text={'update choices'}
            />
          </ButtonWrapper>
        ) : (
          <Navigation
            prevStep={prevStep}
            nextStep={nextStep}
            updateUserInfo={updateUserInfo}
            passedValidation={validation}
          />
        )}
      </Wrapper>
    </>
  )
}

export default Step2
