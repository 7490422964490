import React from 'react'
import styled from 'styled-components'

import { mobileVW, newDesktopVW, colors, desktopBreakpoint } from '../../styles'

const NavigationWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  bottom: ${mobileVW(40)};
  padding: 0 ${mobileVW(32)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 66px;
    bottom: auto;
    top: 800px;
  }
  .nav_button {
    border-radius: 50%;
    background: ${colors.sand};
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    width: ${mobileVW(50)};
    height: ${mobileVW(50)};
    @media (min-width: ${desktopBreakpoint}) {
      width: 77px;
      height: 77px;
    }

    &::after {
      content: '';
      display: block;
      transform: rotate(-45deg);
      width: ${mobileVW(10)};
      height: ${mobileVW(10)};
      border: solid ${newDesktopVW(1)} ${colors.brownDarkest};
      @media (min-width: ${desktopBreakpoint}) {
        width: 21px;
        height: 21px;
        border: solid 2px ${colors.brownDarkest};
      }
    }
  }
`
const Prev = styled.button`
  &::after {
    border-right: none !important;
    border-bottom: none !important;
    margin-right: ${newDesktopVW(-10)};
  }
`
const Next = styled.button`
  &::after {
    border-top: none !important;
    border-left: none !important;
    margin-right: ${newDesktopVW(10)};
  }
`

const Navigation = ({
  prevStep,
  nextStep,
  updateUserInfo,
  passedValidation,
  warningMessages,
}) => {
  const updateUserAndView = stepDirection => {
    if (stepDirection == 'next') {
      if (passedValidation) {
        nextStep()
        updateUserInfo ? updateUserInfo() : null
      } else {
        warningMessages ? warningMessages() : null
      }
    } else {
      prevStep()
    }
  }

  return (
    <NavigationWrapper className='navigation_wrapper'>
      <Prev
        className='nav_button prev_button'
        onClick={() => updateUserAndView()}
      />
      <Next
        className='nav_button next_button'
        onClick={() => updateUserAndView('next')}
      />
    </NavigationWrapper>
  )
}

export default Navigation
