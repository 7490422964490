import { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import queryOrders from '../shopify/queryOrders'

// Hooks
import useAuth from './useAuth'
import useStoreCtx from './context/useStoreCtx'

const useOrders = () => {
  const { user } = useAuth()
  const [orders, setOrders] = useState(null)
  const stores = useStoreCtx()

  useEffect(() => {
    if (user) {
      const unsubscribe = firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .collection('orders')
        .onSnapshot(async ({ docs }) => {
          const orderIds = docs
            .map(doc => doc.data())
            .reduce(
              (groups, { storeSubdomain, id }) => ({
                ...groups,
                [storeSubdomain]: [...(groups[storeSubdomain] || []), id],
              }),
              {},
            )

          const responses = await Promise.all(
            Object.entries(orderIds).map(([storeSubdomain, ids]) =>
              queryOrders(stores[storeSubdomain].client, { ids }),
            ),
          )

          setOrders(
            Object.keys(orderIds).reduce(
              (array, storeSubdomain, index) => [
                ...array,
                ...responses[index].data.nodes.map(order => ({
                  ...order,
                  storeSubdomain,
                })),
              ],
              [],
            ),
          )
        })
      return unsubscribe
    }

    setOrders(null)
  }, [user, stores])

  return orders
}

export default useOrders
