import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useIsMobile from '../../../hooks/useIsMobile'
import Pencil from '../../graphics/Pencil'

import { mobileVW, colors, desktopBreakpoint, font } from '../../../styles'

const Wrapper = styled.div`
  position: relative;
  flex-grow: ${props => (props.stretch ? '1' : '0')};
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}` : 'none')};
  input {
    font-family: ${font.larsseit};
    width: 100%;
    color: ${colors.brownDarkest};
    font-size: ${mobileVW(14)};
    line-height: ${mobileVW(24)};
    padding: ${mobileVW(19)} ${mobileVW(17)} ${mobileVW(7)};
    resize: none;
    appearance: textfield;
    text-transform: uppercase;
    margin-top: ${mobileVW(12)};
    border: ${props =>
      props.border
        ? `solid ${mobileVW(1.5)} ${colors.orangeNew}`
        : `solid ${mobileVW(1.5)} ${colors.beigeUltraLight}`};
    border-radius: ${mobileVW(6)};

    &[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-color: ${props =>
        props.border ? `${colors.orangeNew}` : `${colors.brownDark}`};
      width: ${mobileVW(19)};
      height: ${mobileVW(19)};
      margin-top: 0;
      padding: 0;

      &:focus {
        outline: none;
      }

      &:checked {
        overflow: hidden;
        ::after {
          content: '';
          display: block;
          height: ${mobileVW(16)};
          background-repeat: no-repeat;
          background-position: center;
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3334 1L5.00002 8.33333L1.66669 5' stroke='%2365473F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          @media (min-width: ${desktopBreakpoint}) {
            height: 16px;
          }
        }
      }
      @media (min-width: ${desktopBreakpoint}) {
        width: 20px;
        height: 20px;
        border-width: 1.5px;
        background: white;
      }
    }

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 14px;
      line-height: 24px;
      padding: 16px 20px 12px;
      margin-top: 12px;
      overflow: hidden;
      border-radius: 6px;
      border: ${props =>
        props.border
          ? `solid 1.5px ${colors.orangeNew}`
          : `solid 1.5px ${colors.beigeUltraLight}`};
    }

    &::placeholder {
      color: ${colors.brownDarkest};
      text-transform: uppercase;
      opacity: ${props => props.opacity};
      font-size: ${mobileVW(14)};
      line-height: ${mobileVW(24)};
      @media (min-width: ${desktopBreakpoint}) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
`

const Icon = styled.div`
  display: ${props => (props.hide ? 'none' : 'flex')};
  background-color: ${colors.beigeUltraLight};
  width: ${mobileVW(27)};
  height: ${mobileVW(27)};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${mobileVW(25)};
  right: ${mobileVW(10)};
  @media (min-width: ${desktopBreakpoint}) {
    width: 44px;
    height: 44px;
    top: 30px;
    right: 18px;
  }

  svg {
    width: ${mobileVW(13)};
    height: ${mobileVW(13)};
    @media (min-width: ${desktopBreakpoint}) {
      width: 22px;
      height: 22px;
    }
  }
`

const StyledInput = styled.input`
  &[type='text'],
  &[type='number'],
  &[type='password'],
  &[type='email'],
  &[type='tel'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &::placeholder {
    opacity: ${props => props.opacity};
  }
`

const StyledPlaceholder = styled.div`
  pointer-events: none;
  text-transform: uppercase;
  position: absolute;
  bottom: ${mobileVW(20)};
  font-size: ${mobileVW(14)};
  color: #c8c8c8;
  transition: transform 0.3s ease-in-out;
  transform-origin: ${mobileVW(60)} ${mobileVW(7)};
  opacity: ${props => (props.show ? '1' : '0')};
  transform: ${props => (props.show ? 'translateY(-80%) scale(0.7)' : null)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    bottom: 20px;
    transform-origin: 60px 7px;
  }
`

const Input = ({
  name,
  value,
  type,
  placeholder,
  onChange,
  onFocus,
  checked,
  hideIcon,
  border,
  customPlaceholder,
  stretch,
  maxWidth,
}) => {
  const isMobile = useIsMobile()
  const [isFocused, setIsFocused] = useState(false)
  const [inputValue, setInputValue] = useState(value)
  // const [parsedValue, setValue] = useState()

  const focusInput = () => {
    onFocus ? onFocus : null
    setIsFocused(true)
  }

  const unfocusInput = () => {
    setIsFocused(false)
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <Wrapper
      className='input_wrapper'
      border={border}
      stretch={stretch}
      maxWidth={maxWidth}
    >
      {customPlaceholder ? (
        <StyledPlaceholder show={isFocused || isMobile}>
          {customPlaceholder}
        </StyledPlaceholder>
      ) : (
        <StyledPlaceholder show={isFocused || inputValue}>
          {placeholder}
        </StyledPlaceholder>
      )}
      <StyledInput
        onFocus={() => focusInput()}
        name={name}
        type={type}
        checked={checked}
        value={inputValue}
        placeholder={`${placeholder}`}
        onChange={onChange}
        onBlur={() => unfocusInput()}
        opacity={customPlaceholder ? '100%' : '30%'}
      ></StyledInput>
      <Icon hide={hideIcon}>
        <Pencil />
      </Icon>
    </Wrapper>
  )
}

export default Input
