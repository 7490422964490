import { useEffect, useRef, useReducer } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'

// Hooks
import useAuth from '../../hooks/useAuth'

const DEFAULT_OPTIONS = {
  initAmount: 3,
  paginate: 6,
}

const INITIAL_STATE = {
  entries: [],
  size: 0,
  loading: true,
  retrieved: 0,
}

const actions = {
  ADD_ENTRIES: 'ADD ENTRIES',
  REMOVE_ENTRY: 'REMOVE ENTRY',
}

function reducer(state, action) {
  switch (action.type) {
    case actions.ADD_ENTRIES:
      return { ...state, entries: [...state.entries, ...action.payload] }
    case actions.REMOVE_ENTRY:
      return {
        ...state,
        entries: state.entries.filter(entry => entry.id === action.payload),
      }
    default:
      return { ...state, ...action.payload }
  }
}

const useSaved = (_collection, _options = {}) => {
  const options = { ...DEFAULT_OPTIONS, ..._options }
  const db = firebase.firestore().collection('users')

  const { user, ready } = useAuth()

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const lastRef = useRef(null)

  // Private Functions

  function _getSize() {
    return new Promise((resolve, reject) => {
      db.doc(user.uid)
        .collection(_collection)
        .where('saved', '==', true)
        .get()
        .then(resolve)
        .catch(reject)
    })
  }

  function _getNextItems(limit = options.paginate) {
    return new Promise(resolve => {
      if (lastRef && lastRef.current) {
        db.doc(user.uid)
          .collection(_collection)
          .where('saved', '==', true)
          .startAfter(lastRef.current)
          .limit(limit)
          .get()
          .then(({ docs, size }) => {
            if (size === 0) return resolve({ entries: [] })
            if (!docs) return resolve({ entries: state.entries })

            const entries = docs.map(doc => doc.data())
            const ref = docs[docs.length - 1]

            lastRef.current = ref

            resolve({ entries })
          })
      } else {
        db.doc(user.uid)
          .collection(_collection)
          .where('saved', '==', true)
          .limit(limit)
          .get()
          .then(({ docs, size }) => {
            if (size === 0) return resolve({ entries: [] })
            if (!docs) return resolve({ entries: state.entries })

            const entries = docs.map(doc => doc.data())
            const ref = docs[docs.length - 1]

            lastRef.current = ref

            resolve({ entries })
          })
      }
    })
  }

  async function _init() {
    const [{ size }, { entries }] = await Promise.all([
      _getSize(),
      _getNextItems(options.initAmount),
    ])

    dispatch({ payload: { loading: false, size, entries } })
  }

  // Callback/Public Functions

  async function remove(id) {
    const [{ size }, { entries: _entries }] = await Promise.all([
      _getSize(),
      _getNextItems(1),
    ])

    const entries = [...state.entries, ..._entries].filter(
      entry => entry.id !== id,
    )

    dispatch({ payload: { entries, size } })
  }

  async function next() {
    const { entries } = await _getNextItems(options.paginate)

    dispatch({ type: actions.ADD_ENTRIES, payload: entries })
  }

  useEffect(() => {
    if (user && ready) _init()
  }, [user, ready])

  return { ...state, remove, next }
}

export default useSaved
