import React from 'react'
import styled from 'styled-components'

// Components
import AllSteps from './AllSteps'

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  height: 100%;
`

const StepWrapper = styled.div`
  display: block;
`

const CreateAccount = ({
  image,
  title,
  step1Message,
  step3Message,
  userPreferences,
}) => {
  return (
    <>
      <Wrapper>
        <StepWrapper>
          <AllSteps
            banner={image}
            bannerTitle={title}
            message={step1Message}
            message3={step3Message}
            userPreferences={userPreferences}
          />
        </StepWrapper>
      </Wrapper>
    </>
  )
}

export default CreateAccount
