import { gql } from 'apollo-boost'
import { variant } from '../fragments/shopify'

const query = gql`
  query orders($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Order {
        orderNumber
        statusUrl
        processedAt
        totalPrice {
          amount
        }
        successfulFulfillments {
          trackingCompany
          trackingInfo(first: 250) {
            number
            url
          }
        }
        lineItems(first: 250) {
          edges {
            node {
              customAttributes {
                key
                value
              }
              quantity
              variant {
                ...Variant
              }
            }
          }
        }
      }
    }
  }
  ${variant}
`

/**
 * https://help.shopify.com/en/api/storefront-api/reference/queryroot
 *
 * @param {{ids: [ID]}} variables
 */
const queryOrders = (client, variables) => client.query({ query, variables })

export default queryOrders
