import React from 'react'

const Pencil = props => (
  <svg viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.8938 1.6699L8.08097 13.6658C8.07118 13.6806 8.06233 13.6959 8.05447 13.7118L8.03883 13.7433V13.748C8.03452 13.7571 8.03053 13.7663 8.02688 13.7757L8.01699 13.801L8.01673 13.8204C8.01342 13.8367 8.01108 13.8532 8.00971 13.8698L8.00926 13.878L8.08097 13.6658L8.00922 13.9139V13.9164C8.00917 13.9187 8.00908 13.921 8.009 13.9232C8.00882 13.928 8.00864 13.9328 8.00877 13.9376L8.71805 20.4601L8.54317 20.2603L8.37521 20.2242H6.02298V20.2802L5.8246 20.376L5.75308 20.7574L5.99132 21.041L6.16241 21.0652H8.39359L8.70766 20.9136L8.75439 20.6644C8.76708 20.6974 8.78393 20.7289 8.80462 20.7579C8.84593 20.816 8.90125 20.8626 8.96543 20.8936C9.02962 20.9245 9.10058 20.9387 9.1717 20.9349C9.24282 20.9311 9.31244 20.909 9.37296 20.8714L14.9725 17.3504C14.9913 17.3422 15.0095 17.3327 15.027 17.3218L15.0967 17.2785L15.0969 17.2578L15.0993 17.2553C15.1053 17.2496 15.1111 17.2438 15.1167 17.2378L15.1178 17.2366C15.1658 17.1841 15.1997 17.1203 15.2165 17.0514L21.0043 5.11318C21.355 4.38981 21.0508 3.51374 20.3269 3.16367L15.8431 0.992205C15.6609 0.904074 15.4692 0.860713 15.2813 0.851438L15.1239 0.843672V0.852465C14.6133 0.882052 14.1328 1.177 13.8938 1.6699ZM14.6529 2.03782C14.7646 1.80756 14.9911 1.68194 15.2274 1.69191L15.2359 1.69238C15.3188 1.69701 15.3996 1.71513 15.4754 1.75177L16.1477 2.07869L10.1844 14.2811L8.99706 13.7085L14.6529 2.03782ZM10.9436 14.6488L16.9069 2.44663L18.523 3.22618L12.5589 15.4311L10.9436 14.6488ZM8.93804 14.6167L13.9129 17.0198L11.7447 18.3895L11.7332 18.3845L9.21708 17.167L8.93804 14.6167ZM13.3183 15.7986L19.2839 3.59791L19.9593 3.92332C20.2704 4.07382 20.3958 4.43493 20.2452 4.74549L14.589 16.4095L13.3183 15.7986ZM9.49823 19.8002L9.32267 18.1521L10.8971 18.9167L9.49823 19.8002Z'
      fill='#18100E'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.1168 20.2802L0.92094 20.3748L0.837241 20.7574L1.08709 21.0413L1.25622 21.0652H3.47999L3.79154 20.9148L3.87565 20.5303L3.6277 20.2598L3.46161 20.2242H1.1168V20.2802Z'
      fill='#18100E'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.38512 20.2802L4.18673 20.376L4.11522 20.7574L4.35345 21.041L4.52454 21.0652H5.11786L5.43193 20.9136L5.50379 20.5304L5.26744 20.2603L5.09948 20.2242H4.38512V20.2802Z'
      fill='#18100E'
    />
  </svg>
)

export default Pencil
